<template>
  <div class="property-list-container">
    <Header
      :details="{
        back: false,
        title: 'Builders',
      }"
    />
    <div class="wrapper-content">
      <header class="header-list flex">
        <div class="flex sub-header-options">
          <div class="search"><input type="text" v-model="search" :placeholder="$t('overall.search')" /></div>
          <router-link :to="{ name: 'create-builder' }" class="cta align-right">
            <plusSVG />
            {{ $t("builders.create") }}
          </router-link>
        </div>
      </header>
      <div class="table-list">
        <ul class="labels">
          <li @click="orderListBy('name', builders)" class="table-list__line">
            <button>{{ $t("overall.name") }}</button>
          </li>
          <li @click="orderListBy('address.city', builders)" class="table-list__line">
            <button>{{ $t("overall.location") }}</button>
          </li>
          <li @click="orderListBy('emails.0.element', builders)" class="table-list__line">
            <button>{{ $t("overall.email") }}</button>
          </li>
          <li @click="orderListBy('phones.0.element', builders)" class="table-list__line">
            <button>{{ $t("overall.phone") }}</button>
          </li>
          <li @click="orderListBy('website', builders)" class="table-list__line">
            <button>{{ $t("overall.website") }}</button>
          </li>
        </ul>
        <div class="elements">
          <template v-if="!loading">
            <div class="line" v-for="b in elementsToDisplay" :key="b.token">
              <router-link :to="{ name: 'edit-builder', params: { token: b.token } }">
                <div class="table-list__line">{{ b.name ? b.name : "-" }}</div>
                <div class="table-list__line">{{ b.address ? b.address.city : "-" }}</div>
                <div class="table-list__line">{{ b.emails && b.emails.length ? b.emails[0].element : "-" }}</div>
                <div class="table-list__line">{{ b.phones && b.phones.length ? b.phones[0].element : "-" }}</div>
                <div class="table-list__line">{{ b.website ? b.website : "-" }}</div>
              </router-link>
            </div>
          </template>
          <span class="no-element" v-else>{{ $t("builders.loading_builders") }}</span>
          <span class="no-element" v-if="builders.length < 1 && !loading">{{ $t("builders.no_builder") }}</span>
        </div>
      </div>
      <Pagination v-if="builders.length" :value="builders" @changeCurrentPage="elementsToDisplay = $event" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import plusSVG from "@/assets/img/plus.svg";
import modalBus from "@/bus/modal-bus";

export default Vue.extend({
  components: { plusSVG },
  name: "BuildersList",
  computed: {
    numberElements() {
      return this.builders.length;
    },
    numberPagination() {
      return Math.round(this.numberElements / this.elementPerPage);
    },
  },
  watch: {
    search() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.loading = true;
        this.$axios.get("/api/builders/search?search=" + this.search).then((res) => {
          this.builders = res.data;
          this.loading = false;
          this.timer = null;
          if (!this.properties.length) {
            this.elementsToDisplay = [];
          }
        });
      }, 300);
    },
  },
  data() {
    return {
      timer: null,
      builders: [],
      elementsToDisplay: [],
      search: "",
      loading: true,
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  methods: {},
  mounted() {
    this.$axios.get("api/builders").then((res) => {
      this.builders = res.data.builders;
      this.builders.forEach((b) => {
        b.translations = b.translations.filter((el) => {
          for (let al of this._global.availableLanguages) {
            if (al.value === el.lang) {
              return true;
            }
          }
        });
      });

      this.loading = false;
    });
  },
});
</script>

<style lang="scss" scoped></style>
